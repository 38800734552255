import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useColorMode } from "theme-ui";
import Section from "@components/Section";
import Logo from "@components/Logo";
import Icons from "@icons";
import mediaqueries from "@styles/media";

import { Link, navigate, graphql, useStaticQuery  } from 'gatsby'
import logo from '../images/logo.svg'
import './header.css'

import {
  copyToClipboard,
  getWindowDimensions,
  getBreakpointFromTheme,
} from "@utils";

const Header = () => (
  <header className="header">
    <div className="css-icons-sttl">
      <Link to="/">
        <img src={logo} className="css-icons-sttl" alt="logo"/>
      </Link>
    </div>

    <div className="dropdown-menu">
      <input type="checkbox" id="checkbox" />
      <label id="burger" htmlFor="checkbox">
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </label>
      <div className="menu-content">
        <ul>
          <li>
            <Link to="/" className="menu_ele">
              Work
            </Link>
          </li>
          <li>
            <Link to="/about" className="menu_ele">
              About
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </header>
)

export default Header
