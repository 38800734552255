import React from 'react'
import behance from '../images/behance.svg'
import dribbble from '../images/dribbble.svg'
import facebook from '../images/facebook.svg'
import github from '../images/github.svg'
import instagram from '../images/instagram.svg'
import linkedin from '../images/linkedin.svg'
import medium from '../images/medium.svg'
import twitter from '../images/twitter.svg'
import './layout.css'

const Customfooter = () => (
  <footer>
    <div className="divided-end" />
    <div className="footer-container">
      <div className="w-full sm-w1-2">
        <div className="social_links">
          <a href="https://twitter.com/andreatangredi1" target="_blank" rel="noopener noreferrer">
            <img src={twitter} className="css-icons-sttl" alt="twitter"/>
          </a>
          <a
            href="https://www.linkedin.com/in/andrea-tangredi-406513184/"
            target="_blank"  rel="noopener noreferrer"
          >
            <img src={linkedin} className="css-icons-sttl" alt="linkedin"/>
          </a>
        </div>
      </div>
      <div className="w-full sm-w1-2 css-copyright">
        <span className="sign-12">
          © Andrea Tangredi 2020
        </span>
      </div>
    </div>
  </footer>
)

export default Customfooter
